<template>
  <v-container fluid class="pb-16">

    <div class="d-flex flex-grow-1">
      <div
        style="width:80px;min-width:80px;max-width:80px"
      >
        <YearList
          :selected="year"
          @select="selectYear"
        />
      </div>
      <div
        class="pl-3 flex flex-grow-1"
      >
        <div class="d-flex align-center mt-2 mb-5 justify-space-between">
          <RadioGroup
            field="type"
            :column="false"
            :items="typeOptions"
            :value="findingType"
            @change="selectType"
          />
          <v-layout align-center>
            <div class="field-label text-caption mx-3">
              <span class="font-weight-bold primary--text">
                Finding Status
              </span>
            </div>
            <div
              style="max-width:280px;width:280px"
            >
              <Select
                required
                :items="statusOptions"
                :value="findingStatus"
                @change="selectStatus"
              />
            </div>
          </v-layout>
          <v-spacer />
        </div>
        <DataTable
          :cols="cols"
          :data="items"
          @selectOne="view"
          @sort="sort"
        />
        <DocumentDrawer
          :open="ddo"
          :title="ddt"
          :width="960"
          :stateless="stateless"
          @close="ddo=false"
        >
          <template v-slot:actionMenu>
            <ActionMenu
              :items="actionMenuItems"
            />
          </template>
          <Detail
            v-if="selected"
            :id="selected._id"
            @close="ddo=false"
          >
            <template v-slot:proposed="{ finding }">
              <slot name="proposed" :finding="finding"/>
            </template>
            <template v-slot:cas="{ finding }">
              <slot name="cas" :finding="finding"/>
            </template>
            <template v-slot:notes="{ finding }">
              <slot name="notes" :finding="finding" :close="close"/>
            </template>
          </Detail>
        </DocumentDrawer>
      </div>
    </div>

  </v-container>
</template>

<script>
/* eslint-disable */

import { useFind } from 'feathers-vuex'
import { computed, ref, reactive } from '@vue/composition-api'

import { fields } from '@/models/finding'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import RadioGroup from '@/components/UI/forms/radio-group'
import Select from '@/components/UI/forms/select'
import YearList from '@/components/UI/year-list'

import Detail from './single-view-wrapper'

export default {
  components: {
    ActionMenu,
    Detail,
    DocumentDrawer,
    RadioGroup,
    Select,
    YearList
  },
  data () {
    return {}
  },
  setup(props, context) {
    const { Finding } = context.root.$FeathersVuex.api
    const { $store } = context.root

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)
    const ddo = ref(false)
    const stateless = ref(false)
    const ddt = computed(() => {
      if (!selected.value) return ''
      return selected.value.acbFindingFile.replace('.xml', '')
    })
    const findingType = ref('Non-Conformity')
    const findingStatus = ref(0)
    const user = $store.state.auth.user
    const selected = ref(null)
    const year = ref(new Date().getFullYear())

    const actionMenuItems = computed(() => {
      if (!selected.value) return []
      const items = [
        {
          label: 'Print',
          icon: 'mdi-printer',
          action: print
        }
      ]
      return items
    })

    const statusOptions = [
      { text: 'All', value: 0 },
      { text: 'With Proposed Completion Date', value: 1 },
      { text: 'With Completion Date', value: 2 }
    ]

    const typeOptions = [
      'Non-Conformity',
      'Observation'
    ]

    // need to include the closed date
    // so that the record is reactive to
    // being closed by another user

    const $select = [
      '_id',
      'acbClass',
      'acbACB',
      'acbAuditor',
      'acbDate',
      'acbFindingFile',
      'acbFinding_Type',
      'acbProposedCompDate',
      'acbCompDate',
      'acbCloseDate'
    ]

    // const $sort = reactive({ acbDate: -1 })

    // const $sort = computed(() => ({ acbDate: -1 }))
    const $sort = ref({ acbDate: -1 })
    const $limit = 5000

    // need this to filter the local store
    // when an AC switches CS
    const classClause = computed(() => {
      return user.type === 'ac'
        ? { acbClass: user.assignedTo[acUserSelectedCS.value].value }
        : null
    })

    const dateClause = computed(() => ({
      acbDate: {
        $gte: new Date(`${year.value}-01-01`),
        $lt: new Date(`${year.value + 1}-01-01`)
      }
    }))

    const statusClause = computed(() => {
      switch (findingStatus.value) {
        case 1:
          return { acbProposedCompDate: { $ne: null } }
        case 2:
          return { acbCompDate: { $ne: null } }
      }
    })

    const typeClause = computed(() => {
      return findingType.value !== 'All'
        ? { acbFinding_Type: findingType.value }
        : null
    })

    const params = computed(() => {
      const obCutoff = new Date( Date.now() - (Number(3.154e+10) * 1.5) ) // 18 'months' in milliseconds
      // this query is used locally on the vuex store
      return {
        qid: 'acOpenFindings',
        query: {
          $select,
          $sort: $sort.value,
          $limit,
          // either
          acbCloseDate: null,
          $or: [
            {
              acbFinding_Type: 'Non-Conformity'
            },
            // or
            {
              acbCloseDate: null,
              acbFinding_Type: 'Observation',
              // and
              // younger than 18 months
              acbDate: { $gte: obCutoff }
            }
          ],
          ...classClause.value,
          ...dateClause.value,
          ...statusClause.value,
          ...typeClause.value,
        }
      }
    })

    const { items } = useFind({
      model: Finding,
      params: params,
    })

    const selectType = item => {
      findingType.value = item.value
    }

    const selectStatus = item => {
      findingStatus.value = item.value.value
    }

    const selectYear = value => {
      year.value = value
    }

    const cols = [
        'acbClass',
        'acbACB',
        'acbAuditor',
        'acbDate',
        'acbFindingFile',
        'acbProposedCompDate',
        'acbCompDate'
      ]
      .map(c => fields.find(f => f.field === c))
      .map(f => ( { ...f, label: f.shortname } ))

    const close = () => {
      ddo.value = false
    }

    const edit = () => {
      // console.log('edit')
    }

    const print = () => {
      // console.log('print')
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    const view = item => {
      selected.value = item
      ddo.value = true
    }

    return {
      actionMenuItems,
      close,
      cols,
      ddo,
      ddt,
      edit,
      items,
      findingStatus,
      findingType,
      print,
      selected,
      selectStatus,
      selectType,
      selectYear,
      sort,
      statusOptions,
      stateless,
      // total,
      typeOptions,
      view,
      year
    }
  }
}
</script>

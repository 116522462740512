<template>
  <h1 v-if="!enabled">
    Advanced Search
  </h1>
  <v-container v-else fluid class="px-4 pb-16">

    <LoadDialog ref="loadDialog" />
    <SaveDialog ref="saveDialog"/>

    <v-row dense no-gutters>
      <v-col
        align-self="center"
        cols="1"
      >
        <label class="primary--text"> Query: </label>
      </v-col>
      <v-col cols="9">
        <Query
          :query="query"
          @change="changeQuery"
        />
      </v-col>
      <v-col
        cols="2 mt-2 nowrap d-flex"
        align-self="start"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          :disabled="!query[0].value"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          depressed
          @click="load"
        >
          Load
        </v-btn>
      </v-col>
      <v-col cols="1 mt-4">
        <label class="primary--text"> Columns: </label>
      </v-col>
      <v-col cols="11 mt-3" style="position:relative">
        <ColumnList
          :columns="columns"
          @change="changeColumns"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <Results
          :columns="columns"
          :query="query"
          :label="savedSearch.name"
          @clear="clear"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'

import ColumnList from './columns'
import Query from './query'
import Results from './results'

import LoadDialog from './load-search-dialog.vue'
import SaveDialog from './save-search-dialog.vue'

export default {
  components: {
    ColumnList,
    LoadDialog,
    Query,
    Results,
    SaveDialog
  },
  created () {
    this.clear()
  },
  data () {
    return {
      savedSearch: null,
      total: 0,
      enabled: false
    }
  },
  computed: {
    columns: {
      get(){
        return this.savedSearch.columns
      },
      set (value){
        return this.savedSearch.columns = value
      }
    },
    query() {
      return this.savedSearch.query
    }
  },
  methods: {
    clear() {
      const { SavedSearch } = this.$FeathersVuex.api
      this.savedSearch = new SavedSearch()
    },
    changeColumns (value) {
      this.columns = value
    },
    changeQuery (query) {
      this.query = query
    },
    async load () {
      const { result, selected } = await this.$refs.loadDialog.open()
      if (result) {
        const ss = await this.$store.dispatch('saved-searches/get', selected._id)
        Vue.nextTick(() => {
          this.savedSearch = ss
        })
      }
    },
    async save () {
      if ( this.savedSearch._id ) {
        return this.savedSearch.save()
      } else {
        const { result, name } = await this.$refs.saveDialog.open()
        if (result) {
          this.savedSearch.name = name
          this.savedSearch.create()
        }
      }
    }
  }

}
</script>

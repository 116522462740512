import { render, staticRenderFns } from "./textarea.vue?vue&type=template&id=7a21173e&scoped=true&"
import script from "./textarea.vue?vue&type=script&lang=js&"
export * from "./textarea.vue?vue&type=script&lang=js&"
import style0 from "./textarea.vue?vue&type=style&index=0&lang=css&"
import style1 from "./textarea.vue?vue&type=style&index=1&id=7a21173e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a21173e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})

<template>
  <v-row dense no-gutters>

    <v-col cols="12">
      <v-divider class="my-3"/>
    </v-col>

    <v-col
      v-if="hasQuery"
      cols="12 mb-2 px-2"
      class="d-flex align-center justify-space-between"
    >
      <div class="d-flex align-center text-h5">
        Results for {{ label || 'unsaved search'}}
      </div>
      <div
        class=""
      >
        <v-btn
          color="error"
          text
          @click="$emit('clear')"
        >
          Clear Query
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          depressed
          :disabled="!total"
          :loading="downloading"
          @click="download"
        >
          Download Results
        </v-btn>
      </div>
    </v-col>

    <div v-else>No Results</div>

    <v-col
      cols="12"
      v-if="hasQuery"
    >
      <!-- {{ query }} -->
      <DataTable
        :cols="cols"
        :data="items"
        @selectOne="view"
        @sort="sort"
      >
        <!-- <template v-slot:item.acbAuditType="{ item }">
          {{ item }}
        </template> -->
      </DataTable>

      <v-divider class="mb-3" />

      <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
        <template #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }">
          <div
            v-if="pageCount > 1"
            class="d-flex justify-space-between"
          >
            <div class="d-flex align-center primary--text text-subtitle-1 ">
              <div class="px-2 mr-3">{{ paginationString }}</div>
              <v-btn
                class="px-2"
                text
                small
                color="primary"
                :disabled="total > showAllThreshold"
                @click="showAll"
              ><span class="text-subtitle-1" style="text-transform: none">Show All Results</span></v-btn>
            </div>
            <Pagination
              v-model="currentPage"
              :length="pageCount"
              :total-visible="9"
              @input="toPage"
            />
          </div>
        </template>
      </FeathersVuexPagination>
    </v-col>

    <v-col
      v-if="!columns.length"
      cols="12"
    >
      <v-alert
        class="mt-3"
        dense
        text
        type="info"
      >
        Please select at least one output column.
      </v-alert>
    </v-col>
    <DocumentDrawer
      hide-print
      :open="ddo"
      :title="ddt"
      :width="960"
      :stateless="false"
      @close="ddo=false"
    >
      <SingleView
        v-if="selected"
        :id="selected._id"
      />
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref, watch } from '@vue/composition-api'
import { dictionary } from '@/models/finding'

import DocumentDrawer from '@/components/UI/document-drawer'
import Pagination from '@/components/UI/pagination'

import SingleView from './single-view-wrapper'

export default {
  components: {
    DocumentDrawer,
    Pagination,
    SingleView
  },
  props: {
    columns: {
      type: Array,
      default () { return [] }
    },
    label: {
      type: String,
      default: ''
    },
    query: {
      type: Array,
      default () { return [] }
    }
  },
  setup(props, context) {
    const { Finding } = context.root.$FeathersVuex.api
    const { $options, $store } = context.root
    const user = $store.state.auth.user

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)

    const downloading = ref(false)

    const ddo = ref(false)

    const selected = ref(null)
    const limit = ref(10)
    const showAllThreshold = 1000

    const pagination = ref({
      $limit: limit,
      $skip: 0
    })

    const ddt = computed(() => {
      if (!selected.value) return ''
      return selected.value.acbFindingFile.replace('.xml', '')
    })

    watch(
      () => props.query,
      () => {
        // reset when the query changes
        pagination.value.$limit = 10
        pagination.value.$skip = 0
      }
    )

    watch(
      () => props.columns,
      () => {
        // reset when the columns changes
        pagination.value.$limit = 10
        pagination.value.$skip = 0
      }
    )

    const $sort = ref({ acbDate: -1 })

    // need this to filter the local store
    // when an AC switches CS
    const classClause = computed(() => {
      return user.type === 'ac'
        ? { acbClass: user.assignedTo[acUserSelectedCS.value].value }
        : null
    })

    const params = computed(() => {

      if (!props.query[0].value) {
        return false
      }

      if (!props.columns.length) {
        return false
      }

      let select = [...props.columns]

      // also need to select all query fields
      // else the mongoose query won't return results :/
      const queryFields = props.query.reduce((accumulator, parameter) => ([
          ...accumulator,
          parameter.field
        ]), [])

      select = [...select, ...queryFields]

      // console.log({query: {
            // ...classClause.value}})

      const escapeRegExp = string => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
      }

      return {
        qid: 'advancedSearch',
        paginate: true,
        query: {
            $and: props.query.reduce((accumulator, { field, opcode, value }) => ([
              ...accumulator,
              // classClause,
              {
                // handle opcodes in a tertiary statement
                [field]: opcode === 'eq' ? value
                       : opcode === '~'  ? { $regex : escapeRegExp(value), $options: 'i' }
                       : opcode === 'ne' ? { $ne : value }
                       : opcode === 'gt' ? value
                       : opcode === 'lt' ? value
                       : value
              }
            ]), []),
            $select: select,
            $sort: $sort.value,
            ...pagination.value,
            ...classClause.value,
          }
        }
      }
    )

    const { items, latestQuery, paginationData } = useFind({
      model: Finding,
      params,
      fetchParams: params
    })

    const total = computed(() => {
      return ((paginationData.value.advancedSearch || {}).mostRecent || {}).total
    })

    const paginationString = computed(() =>{
      const t = total.value
      const r = $options.filters.pluralize('Result', t)
      const l = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.limit : 0
      const s = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.skip : 0
      return `${s} - ${Math.min(s+l, t)} of ${t} ${r}`
    })

    const hasQuery = computed(() => {
      return props.query[0].value
    })

    const cols = computed(() => {
      return props.columns.sort().map(f => {
        const df = dictionary[f]
        return {
          field: df.field,
          filter: df.filter,
          label: df.shortname,
          truncate: df.truncate,
          width: '1%',
          valueFunction: df.valueFunction
        }
      }).sort((a, b) => a.label > b.label ? 1 : -1)
    })

    const download = async () => {
      downloading.value = true
      const query = {
        ...latestQuery.value.query,
        ...classClause.value,
        $skip: 0,
        $limit: 100000,
      }
      // needed at the server side
      if (user.type === 'ac') {
        query.$cs = $store.state.acUserSelectedCS
      }
      $store.dispatch('getXLSXDoc', { query }).then(() => {
        downloading.value = false
      })
    }

    const view = ( item ) => {
      selected.value = item
      ddo.value = true
    }

    const save = () => {
      // console.log('save search')
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    const showAll = () => {
      limit.value = 100000
    }

    return {
      cols,
      ddo,
      ddt,
      download,
      downloading,
      hasQuery,
      items,
      latestQuery,
      pagination,
      paginationString,
      params,
      save,
      selected,
      showAll,
      showAllThreshold,
      sort,
      total,
      view
    }
  }
}
</script>

<template>
      <div class="d-flex my-2">
<!--         <v-col cols="1">
          <div class="text-caption primary--text font-weight-bold pr-3">
            Field
          </div>
        </v-col> -->
        <div class="d-flex flex-grow-1 mx-n1">
          <div
            class="px-1"
            style="width:40%"
          >
            <Select
              field="field"
              label="Field"
              required
              :items="fields"
              :value="value.field"
              @change="change"
            />
          </div>
          <div
            class="px-1"
            style="width:30%"
          >
            <Select
              field="opcode"
              required
              :disabled="!value.field"
              :items="opcodes"
              :value="value.opcode"
              @change="change"
            />
          </div>
          <div
            class="px-1"
            style="width:30%"
          >
            <InputText
              field="value"
              :disabled="!value.opcode"
              :value="value.value"
              @change="change"
            />
          </div>
        </div>
        <div
          class="px-2"
          style="width:100px"
        >
          <v-tooltip
            top
            v-if="showRemove"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="pa-0"
                color="error"
                depressed
                icon
                @click="$emit('remove', value)"
              >
                <v-icon>
                      mdi-minus
                </v-icon>
              </v-btn>
            </template>
            <span>Remove this field constraint</span>
          </v-tooltip>
          <v-tooltip
            v-if="showAdd"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="pa-0"
                color="primary"
                depressed
                icon
                :disabled="!complete"
                @click="$emit('add')"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Add additional field constraint</span>
          </v-tooltip>
        </div>
      </div>


      <!-- {{ field }} | {{ opcode }} | {{ value }} -->

<!--   <v-sheet
    class="px-2"
    rounded
    color="#eee"
  > -->
    <!-- <v-container fluid class="ma-0 pa-1"> -->
      <!-- <v-row dense align="center"> -->
<!--         <v-col cols="1">
          <div class="text-caption primary--text font-weight-bold pr-3">
            Field
          </div>
        </v-col> -->
<!--         <v-col cols="4">
          <Select
            required
            label="Field"
            :items="fields"
            @change="changeF"
          />
        </v-col>
        <v-col cols="2">
          <Select
            required
            :disabled="!field"
            :items="opcodes"
            @change="changeO"
          />
        </v-col>
        <v-col cols="4">
          <InputText
            :disabled="!(field && opcode)"
            @change="changeV"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            class="pa-0 mx-1"
            icon
            color="primary"
            depressed
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            class="pa-0 mx-1"
            icon
            color="primary"
            depressed
          >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
 -->      <!-- {{ field }} | {{ opcode }} | {{ value }} -->
    <!-- </v-container> -->
  <!-- </v-sheet> -->
</template>

<style scoped>
  button.v-btn{min-width: 38px !important;}
</style>

<script>
import InputText from '@/components/UI/forms/input-text'
import Select from '@/components/UI/forms/select'
import { searchable } from '@/models/finding'

export default {
  props: {
    showAdd: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() { return {} }
    }
  },
  components: {
    InputText,
    Select
  },
  data() {
    return {
      field: null,
      opcode: null,
      opcodes: [
        { text: 'Contains', value: '~' },
        { text: 'Equals', value: 'eq' },
        { text: 'Not Equals', value: 'ne' },
        { text: 'Greater Than', value: 'gt' },
        { text: 'Less Than', value: 'lt' },
      ]
    }
  },
  computed: {
    fields() {
      return searchable('longname').map(f => ({ text: f.longname, value: f.field }))
    },
    complete() {
      return this.value.field && this.value.opcode && this.value.value
    }
  },
  methods: {
    changeF({ value }) {
      this.value.field = value.value
      this.field = value
      this.change()
    },
    changeO({ value }) {
      this.opcode = value
      this.change()
    },
    changeV({ value }) {
      this.value = value
      this.change()
    },
    change({ field, value }) {
      // console.log(field)
      this.value[field] = value.value || value
      // if(this.field && this.opcode && this.value){
      //   this.$emit('change', {
      //     field: this.field.value,
      //     opcode: this.opcode.value,
      //     value: this.value
      //   })
      // } else {
      //   this.$emit('change', null)
      // }
      this.$emit('change', this.value)
    }
  },
  // watch: {
  //   field() {
  //     if(this.field && this.opcode && this.value){
  //       this.$emit('change', {  })
  //     }
  //     console.log(this.field)
  //   }
  //   opcode() {
  //     console.log(this.field)
  //   }
  //   value() {
  //     console.log(this.field)
  //   }
  // }
}
</script>

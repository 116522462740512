<template>
  <v-container v-if="finding"
    class="px-6 mt-0"
    fluid
  >
    <v-row
      v-if="finding"
      dense
      no-gutters
    >
      <v-col class="">
        <Finding :finding="finding">
          <template v-slot:proposed="{ finding }">
            <slot name="proposed" :finding="finding"/>
          </template>
          <template v-slot:cas="{ finding }">
            <slot name="cas" :finding="finding"/>
          </template>
          <template v-slot:notes="{ finding }">
            <slot name="notes" :finding="finding"/>
          </template>
        </Finding>
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>
  .v-btn{
    min-width: 38px !important;
    width: 38px !important;
  }
</style>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import Finding from "@/components/shared/findings/single-view"

export default {
  components: {
    Finding
  },
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
  },
  setup(props, context) {
    const { Finding } = context.root.$FeathersVuex.api
    const id = computed(() => {
      return props.id
    })

    // older findings have no metadata
    const accepted = computed(() => {
      return finding.value.metadata.accepted
    })

    const { item: finding } = useGet({
      model: Finding,
      id
    })

    return {
      accepted,
      // hasLoaded,
      finding,
    }
  }
}
</script>

<template>
  <Ask
    ref="ask"
    :canAccept="Boolean(selected)"
  >
    <v-list class="list mt-4" dense>
      <v-list-item-group
        v-model="selected"
        color="primary"
        @change="change"
      >
        <v-list-item
          v-for="(item, i) in items"
          class="content px-2"
          :key="i"
          :value="item"
        >
          <v-list-item-content>
            {{ item.name || 'Unnamed search' }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </Ask>
</template>

<style scoped>
  .list{
    height:320px;
    overflow-y:auto;
    /*border: 1px solid rgba(0, 0, 0, 0.125);*/
  }
  .list .content:not(:last-child){
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)
  }
</style>

<script>
export default {
  props: {},
  components: {},
  data () {
    return {
      items: [],
      selected: null
    }
  },
  methods: {
    change (value) {
      this.selected = value
    },
    async open () {
      this.selected = null
      const query = { query: { $limit: 100, $sort: { _id: -1 }, $select: ['name'] } }
      const resp = await this.$store.dispatch('saved-searches/find', query)
      this.items = resp.data

      const result = await this.$refs.ask.open(
        'Load Saved Search',
        '',
        {
          cancelLabel: 'Cancel',
          acceptLabel: 'load',
          reverseActions: true,
          icon: 'mdi-folder-open',
          width: 460
        }
      )

      return {
        result: result,
        selected: this.selected
      }
   }
  }
}
</script>
